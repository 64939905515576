import React from 'react';
import './Hero.css'; 

const Hero = () => {
    return (
        <div className="hero-container">
            <div className="hero-content">
            </div>
        </div>
    );
}

export default Hero;